import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import { string, bool } from "prop-types";
import FormLabel from "@material-ui/core/FormLabel";
//import $ from "jquery";

var globalTheme = "";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  margin: {
    height: 0,
  },
  formLabelroot: {
    display: "flex",
    paddingLeft: "0px !important",
    alignItems: "center",
    color: "#000 !important",
    fontSize: "14px",
    fontWeight: "400",
  },
  formLabelrootInline: {
    paddingLeft: "0px ",
    color: "#585555",
    fontSize: "30px",
    padding: " 10px",
    fontWeight: "500",
    display: "flex",
    paddingTop: "0px ",
  },
  formLabelRangeValue: {
    color: "#fff",
    fontSize: (props) =>
      props.isFromHome ? "14px !important" : "35px !important",
    alignItems: "center",
    fontWeight: "500",
    // background: "#fff",
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
    minWidth: "30%",
    background: (props) => `${props?.themecolors?.lightBackgroundColor}`,
    // border: (props) => `${themecolors.onHoverFormControlBorder(props.theme)}`,
    borderRadius: "2px",
    padding: "6px 15px 6px 15px",
    "&:hover": {
      transform: "scale(1.02)",
      transition: "0.2s",
    },
  },
  mainRangeValue: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: (props) => (props.isFromHome ? "absolute" : ""),
    right: (props) => (props.isFromHome ? "50px" : ""),
    top: (props) => (props.isFromHome ? "50px" : ""),
    fontSize: (props) => (props.isFromHome ? "14px !important" : ""),
  },
  iconMain: { position: "absolute", paddingLeft: "7px", fontSize: "27px" },
}));

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: (props) => `${props?.themecolors?.lightBackgroundColor}`,
  },
  tooltip: {
    backgroundColor: (props) => `${props?.themecolors?.lightBackgroundColor}`,
    height: "30px",
    color: "#fff",
    fontSize: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  const classes = useStylesBootstrap({ globalTheme });
  return (
    <Tooltip
      TransitionComponent={Zoom}
      open={open}
      enterTouchDelay={1}
      placement="bottom"
      title={value}
      arrow
      classes={classes}
    >
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

const PrettoSlider = withStyles({
  root: {
    color: (props) => `${props?.themecolors?.backgroundColor}`,
    height: 25,
  },
  thumb: {
    backgroundColor: "#fff",
    border: "4px solid currentColor",
    height: 35,
    width: 35,
    marginTop: -10.5,
    marginLeft: -14.5,
    boxShadow: "none",
    "&:focus, &:hover, &$active": {},
    transition: "0.1s",
  },
  active: {
    border: "solid 6px currentColor",
  },
  valueLabel: {
    left: "calc(-50% + 20px)",
  },
  track: {
    height: 15,
    borderRadius: 10,
    transition: "0.1s",
    backgroundColor: (props) => `${props?.themecolors?.backgroundColor}`,
  },
  rail: {
    height: 15,
    borderRadius: 10,
    backgroundColor: (props) => `${props?.themecolors?.lineargradient}`,
    transition: "0.1s",
  },
})(Slider);

const RangeSlider = ({
  name,
  label,
  description,
  min,
  max,
  step,
  value,
  type,
  theme_id,
  theme,
  onRangeSliderChange,
  iconName,
  isFromHome,
  themecolors,
  activestep
}) => {
  const classes = useStyles({ themecolors, isFromHome });
  // const [defaultvalue, setValue] = React.useState(value);
  const handleSliderChange = (event, newValue) => {
    //setValue(newValue); globalTheme
    onRangeSliderChange(name, newValue);
  };

  // componentDidupdate
  useEffect(() => {
    globalTheme = theme;
    // $("*").css("font-family", localStorage.getItem("fontFamily"));
  });

  useEffect(()=>{
    if(name==='months')
    onRangeSliderChange(name,1)
  },[name])

  return (
    <div className={classes.root}>
      {!!label && theme_id !== 3 && (
        <FormLabel
          className={
            type === "survey"
              ? classes.formLabelrootInline
              : classes.formLabelroot
          }
        >
          {label}
        </FormLabel>
      )}
      <br />
      <div className={classes.mainRangeValue}>
        {/* <i className={`demo-icon ${iconName} ${classes.iconMain}`}></i> */}
        <FormLabel className={classes.formLabelRangeValue}>
          {description === "$" && description}&nbsp;{" "}
          {/* {["mylifeinsurancecompare.financesolver.co","www.mylifeinsurancecompare.financesolver.co"].includes(window.location.host)&&<><span class="dollar-sign">$</span></>} */}
          {(window.location.host === 'myloan-finder.com'||window.location.host === 'www.myloan-finder.com') && name === 'months' && value
              ? `${value} months`
              : isNaN(value)
              ? "0"
              : value.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).split('.')[0]
          }
          
          {value === max && window.location.host !=='ca.financesolver.co' && window.location.host !== 'myloan-finder.com' && window.location.host === 'www.myloan-finder.com' ? "+" : ""}&nbsp;
          {description !== "$" && description}

        </FormLabel>
      </div>
      <PrettoSlider
      id={`step_${activestep}_${name}`}
        name={name}
        valueLabelDisplay="auto"
        ValueLabelComponent={ValueLabelComponent}
        aria-label="pretto slider"
        value={value}
        onChange={handleSliderChange}
        min={min}
        step={step}
        max={max}
        theme={theme}
        themecolors={themecolors}
      />
    </div>
  );
};

RangeSlider.propTypes = {
  classname: string,
  label: string.isRequired,
  name: string.isRequired,
  isFromHome: bool,
};

RangeSlider.defaultProps = {
  classname: "",
  theme_id: 0,
  isFromHome: false,
};

export default RangeSlider;
